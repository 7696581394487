import React from 'react'
import { Helmet } from 'react-helmet-async';

export default function Products() {
    return (
        <div className="content">
            <Helmet>
                <title>Цены - копченая рыба оптом</title>
                <meta name="description" content="Продажа копченой рыбы высокого качества по выгодной цене от компании 'ТД Любови Власовой'." />
            </Helmet> 
            <h1>Вяленая и копченая рыба оптом</h1>
            <h2>Прайс-лист ООО "ТД Любови Власовой"</h2>
            <table>
                <thead>
                    <tr>
                        <th>№</th>
                        <th>Наименование</th>
                        <th>Цена, руб.</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1</td>
                        <td>Вобла 18+ вяленая</td>
                        <td>405,00</td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>Густера Цимлянская вяленая</td>
                        <td>160,00</td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>Камбала Дальневосточная М вяленая</td>
                        <td>450,00</td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>Камбала Дальневосточная с икрой вяленая</td>
                        <td>900,00</td>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td>Камбала-ерш с икрой вяленая</td>
                        <td>600,00</td>
                    </tr>
                    <tr>
                        <td>6</td>
                        <td>Камбала-ерш «По-царски» вяленая</td>
                        <td>945,00</td>
                    </tr>
                    <tr>
                        <td>7</td>
                        <td>Карась Цимлянский вяленый</td>
                        <td>105,00</td>
                    </tr>
                    <tr>
                        <td>8</td>
                        <td>Карась пласт Цимлянский холодного копчения</td>
                        <td>185,00</td>
                    </tr>
                    <tr>
                        <td>9</td>
                        <td>Лещ Цимлянский 0,5-0,6 кг вяленый</td>
                        <td>180,00</td>
                    </tr>
                    <tr>
                        <td>10</td>
                        <td>Лещ Цимлянский 0,5-0,6 кг холодного копчения</td>
                        <td>180,00</td>
                    </tr>
                    <tr>
                        <td>11</td>
                        <td>Окунь Цимлянский вяленый</td>
                        <td>240,00</td>
                    </tr>
                    <tr>
                        <td>12</td>
                        <td>Сазан Цимлянский потрошенный вяленый</td>
                        <td>190,00</td>
                    </tr>
                    <tr>
                        <td>13</td>
                        <td>Сазан пласт Цимлянский холодного копчения</td>
                        <td>200,00</td>
                    </tr>
                    <tr>
                        <td>14</td>
                        <td>Судак Цимлянский вяленый</td>
                        <td>360,00</td>
                    </tr>
                    <tr>
                        <td>15</td>
                        <td>Чехонь Цимлянская вяленая</td>
                        <td>450,00</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}
