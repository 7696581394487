import React from 'react'
import { Helmet } from 'react-helmet-async';

export default function About() {
    return (
        <div className="about">
            <Helmet>
                <title>О комании ТД Любови Власовой</title>
                <meta name="description" content="Торговый Дом Любови Власовой специализируется на переработке и продаже рыбной продукции. Мы обладаем большим опытом в рыбоперерабатывающей сфере и предлагаем индивидуальные условия сотрудничества." />
            </Helmet>
            <h1>ООО "ТД Любови Власовой"</h1>
            <p>ООО «ТД Любови Власовой» – группа компаний по переработке и продаже рыбной продукции с опытом работы более 20 лет. В составе торгового дома три собственные производственные площадки, рассчитанные на переработку различных видов рыбы. Рыбная продукция соответствует государственным стандартам и проходит тщательный контроль качества.</p>
            <p>Компания работает под руководством опытного технолога, кандидата технических наук, подготавливающего и обучающего специалистов-технологов в рыбной отрасли.</p>
            <p>Наши представительства находятся в Воронежской, Липецкой, Тамбовкой, Белгородской, Ростовской областях, также мы работаем с Тульской, Орловской и Московкой областями.</p>
            <br></br>
            <p>Партнерам предлагаются индивидуальные условия сотрудничества:</p>
            <ul>
                <li>•  Предоставляем персональные условия при заключении договоров</li>
                <li>•  Готовим продукцию к определенной дате с заданными качественными характеристиками и в необходимом объеме</li>
                <li>•  Гарантируем обеспечение продукцией вне зависимости от дефицита сырья рыбы</li>
                <li>•  Обеспечиваем полным комплектом документов</li>
                <li>•  Осуществляем доставку продукции в соответствии с нормативными требованиями и правилами</li>
                <li>•  Возможна любая форма оплаты  </li>
            </ul>
            <br></br>
            <p>Нами были спроектировано и запущено 5 крупных успешно работающих рыбоперерабатывающих предприятия. Компания непрерывно совершенствуется, обменивается опытом с коллегами и передовыми производствами.</p>
            <p>Мы также оказываем помощь в проектировании, разработке технологии и технологических условий, отработке продукции, налаживании производства.</p>
            <br></br>
            <p>Адрес основного производства:  396336, Воронежская область, Новоусманский район, пос. Отрадное, ул. Промышелнная 7</p>
        </div>
    )
}
